import React from 'react';
import Header from './header';
import Termos from './termos';
import { connect } from 'react-redux';
import { changeState, logout } from '../actions/AppActions';
import { URL } from '../variables';
import ChangeCamModal from './changeCamModal';
import ConexaoProcesso from './conexao_processo';
import NotProcesso from './NotProcesso';
// import io from 'socket.io-client';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProcessoUser from './ProcessoUser';
// import * as faceapi from 'face-api.js'
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',
      other_window: false,
      invalid:false
    }
    this.videoRef = React.createRef();
    this.imageRef = React.createRef();
    this.canvas = React.createRef();
    this.user_id = uuidv4();
    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  componentDidMount() {
    this.props.changeState({ loading_home: true });
    // try {
      this.myWorker = new SharedWorker('check.js');
      this.myWorker.port.start();
      this.myWorker.port.postMessage(this.user_id);

      this.myWorker.port.onmessage = e => {
        console.log(e.data);
        if (e.data == true) {
          this.get_user();
        }
        else {
          this.setState({ other_window: true });
        }
      };
    // }
    // catch (e) {
    //   console.log(e);
    // }


  }

  async get_user() {
    const MODEL_URL = '/models'

    // await faceapi.loadSsdMobilenetv1Model(MODEL_URL)
    // await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
    // await faceapi.loadFaceRecognitionModel(MODEL_URL)

    /*await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
    await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
    await faceapi.loadFaceLandmarkModel(MODEL_URL);
    await faceapi.loadFaceRecognitionModel(MODEL_URL);
    await faceapi.loadFaceExpressionModel(MODEL_URL);
    await faceapi.loadAgeGenderModel(MODEL_URL);
    await faceapi.loadFaceDetectionModel(MODEL_URL);

    const options = new faceapi.TinyFaceDetectorOptions({
      inputSize: 128,
      scoreThreshold: 0.3
    });
    console.log('leu models');
    let fullFaceDescriptions = await faceapi.detectAllFaces(this.imageRef.current, options).withFaceLandmarks(true).withFaceDescriptors();
    console.log(this.props.token);*/
    fetch(`${URL}api/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();

          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          // this.props.mudarLoadingHome(false);
          setTimeout(() => {
            this.get_user();
          }, 5000);
        }
        else {
          // this.props.mudarFaculdade(resp.faculdade);
          // if (resp.processo != null) {
          // this.props.mudarProcesso(resp.processo);

          // this.props.mudarObjectWorker(this.object_worker);
          if (resp.user.fotos.length > 0 && true == false) {
            if (resp.processo != null) {
              localStorage.setItem('processo_id', resp.processo.id);
            }
            this.canvas.current.height = this.imageRef.current.height;
            this.canvas.current.width = this.imageRef.current.width;
            this.canvas.current.getContext('2d').drawImage(this.imageRef.current, 0, 0, this.imageRef.current.width, this.imageRef.current.height);
            let imgData2 = this.canvas.current.getContext('2d').getImageData(0, 0, this.imageRef.current.width, this.imageRef.current.height);
            const { height, width, data } = imgData2;
            this.object_worker = new Worker('cocossd.js');
            this.object_worker.postMessage({ image: data, width: width, height: height, first: true });

            let fotos = resp.user.fotos;
            let fotos_final = [];
            for (let i = 0; i < fotos.length; i++) {
              // let img=await faceapi.fetchImage('https://digivest.enterscience.com.br/storage/alunos/Matheus_Carreira_Andrade_23/foto_5f7105a40a035.jpeg');
              // let img = await faceapi.fetchImage(URL + 'storage/' + fotos[i].foto);
              // let img = await fetch(URL + 'storage/' + fotos[i].foto,{mode:'no-cors'});

              // this.canvas.current.height = img.height;
              // this.canvas.current.width = img.width;
              // this.canvas.current.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
              // let imgData2 = this.canvas.current.getContext('2d').getImageData(0, 0, img.width, img.height);
              // let { height, width, data } = imgData2;

              // fotos_final.push({
              //   label: fotos[i].foto,
              //   data: { height, width, data }
              // });
            }

            // this.props.mudarUser(resp.user);
            // this.props.mudarFotosUsuario(fotos_final);
            this.worker = new SharedWorker('face_shared.js');
            this.worker.port.start();

            // this.props.mudarWorker(this.worker);
            this.props.changeState({
              worker: this.worker,
              faculdade: resp.faculdade,
              processo: resp.processo,
              user: resp.user,
              sala: resp.sala,
              loading_home: false,
              fotos_usuario: fotos_final,
              object_worker: this.object_worker,
              processo_resultado: resp.processo_resultado
            });


            //fazer que nem fez com a foto do video, transformar para canvas e pegar os dados da imagem
            // let img=await faceapi.fetchImage('https://digivest.enterscience.com.br/storage/alunos/Matheus_Carreira_Andrade_23/foto_5f7105a40a035.jpeg');
            console.log(fotos_final);
            this.canvas.current.toBlob((e) => {
              this.worker.port.postMessage({ image: data, width: width, height: height, fotos: fotos_final, first: true });

            }, 'image/jpeg');
          }
          else {
            // this.props.mudarUser(resp.user);
            this.props.changeState({
              faculdade: resp.faculdade,
              processo: resp.processo,
              user: resp.user,
              sala: resp.sala,
              loading_home: false,
              processo_resultado: resp.processo_resultado
            });
          }
          // }
          // if (resp.sala != null) {
          // this.props.mudarSala(resp.sala);
          // // }
          // this.props.mudarLoadingHome(false);

        }




      } catch (err) {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        setTimeout(() => {
          this.get_user();
        }, 5000);

      }

    })
      .catch((err) => {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        setTimeout(() => {
          this.get_user();
        }, 5000);
      });
  }

  componentDidUpdate(props) {
    if (this.props.stream != null && this.videoRef.current != null && (props.stream == null || this.props.stream.id != props.stream.id)) {
      this.videoRef.current.srcObject = this.props.stream
    }
  }
  componentWillUnmount() {
    if (this.videoRef.current != null) {
      this.videoRef.current.srcObject = null;
    }
    if (this.worker != null) {
      // this.worker.terminate();
      this.props.changeState({ worker: null });
    }
  }

  render() {
    return (
      <div style={{
        background: '#f8f8fa',
        minHeight: '100vh'
      }}>
        {this.state.other_window == false && <div>

          {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
          <canvas ref={this.canvas} id="background-canvas" style={{ position: 'absolute', top: '-99999999px', left: '-9999999999px' }}></canvas>

          <img ref={this.imageRef} src="/imgs/eu.jpeg" hidden />
          <div id="wrapper">
            <Header />
            {this.props.loading_home == false && <div className="d-none d-sm-block">
              <div className="content-page" style={{ marginLeft: 0 }}>
                <div className="content">
                  {this.props.stream != null && this.props.processo != null && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization=='image') && this.props.processo_resultado != null && this.props.processo_resultado.status != 'F' && <div>
                    <div style={{ position: 'fixed', top: '170px', right: '50px', zIndex: 200 }}>
                      <button onClick={() => this.props.changeState({ change_cam_modal: true })} className="btn btn-primary btn-sm" data-id="content_question" data-toggle="modal"
                        data-target="#change_camera">
                        Alterar
                      </button>
                    </div>
                    <div style={{ position: 'fixed', top: '100px', right: '50px', zIndex: 100 }}>
                      <video src={this.props.stream} autoPlay style={{ height: '100px' }} id="video_oficial" ref={this.videoRef} muted>
                      </video>
                      <ChangeCamModal />
                      <ConexaoProcesso />

                    </div>
                  </div>}

                  <div className="container-fluid">
                    <div className="page-title-box">
                      <div className="row align-items-center">
                        <div className="col-sm-12">
                          <h2 style={{ color: 'black' }}>{this.props.faculdade.name}</h2>
                        </div>
                      </div>
                    </div>
                    {/* {this.props.processo != null &&
                   } */}
                    <Switch>
                      {this.props.processo != null && <>
                        <Route exact path={`${this.props.match.url}`} render={(props2) => <Termos {...props2} />} />
                        <Route exact path={`${this.props.match.url}processo`} render={(props2) => { return (<ProcessoUser {...props2} />) }} />
                      </>}


                      {this.props.processo == null && <Route exact path={`${this.props.match.url}`} component={(props2) => <NotProcesso {...props2} />} />}

                      <OtherRoute path="*" path_name='/' />

                    </Switch>
                    {/* { this.props.children } */}
                    {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                  </div>
                </div>
              </div>
            </div>}
            {this.props.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <h4 style={{ textAlign: 'center' }}>Carregando dados. Por favor, aguarde.</h4>
              </div>
            </div>}

          </div>
          <div className="d-flex d-sm-none" style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <div>

              <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Deslogue e acesse pelo computador para poder visualizar nosso conteúdo
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a className="btn btn-success btn-lg" onClick={this.logout.bind(this)} style={{ width: '80%', color: 'white' }}><i
                  className="mdi mdi-power " style={{ color: 'white' }}></i> Sair</a>
              </div>
            </div>

          </div>
        </div>}

        {this.state.other_window == true && <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 1000000000000, position: 'fixed', bottom: 0, left: 0, right: 0, top: 0, background: 'white' }}>
          <div style={{ margin: 20 }}>
            <h4 style={{ textAlign: 'center', color: 'red' }}>Você já possui uma outra janela deste sistema aberta. Feche-a e atualize esta página para continuar.</h4>
          </div>
        </div>}
      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    faculdade: state.AppReducer.faculdade,
    token: state.AppReducer.token,
    loading_home: state.AppReducer.loading_home,
    stream: state.AppReducer.stream,
    processo: state.AppReducer.processo,
    processo_resultado: state.AppReducer.processo_resultado,
  }
);

export default connect(mapsStateToProps, { changeState, logout })(Home);
