import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarFaculdade, mudarUser, mudarStream, mudarDevices, mudarCamModal, logout,mudarProcesso } from '../actions/AppActions';
import ChangeCamModal from './changeCamModal';
import { Redirect } from 'react-router-dom';

class Termos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_camera: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            redirect: false,
            path: '/processo'
        }
        this.stream = null;
        this.videoRef = React.createRef();
        this.unmont_ = false;

        // this.videoRef=null;
    }
    componentDidMount() {
        this.props.mudarStream(null);
        this.logs_timeout = setTimeout(() => {
            this.salvar_log();
        }, 30000);
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        this.stream = null;
        if (this.videoRef.current != null) {
            this.videoRef.current.srcObject = null;
        }
        this.unmont_ = true;

    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=termo&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if(resp.processo!=null && resp.processo.full_screen!=this.props.processo.full_screen){
                        this.props.mudarProcesso(resp.processo);
                    }
                    if (this.unmont_ == false) {

                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }



    solicitar_camera() {
        this.setState({ loading_camera: true, msg: '', show_solicitar: false });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => this.get_camera()).catch(this.handleError.bind(this));
    }

    async get_camera() {

        var devices = await navigator.mediaDevices.enumerateDevices();
        let device_local = localStorage.getItem("deviceId")
        console.log(devices);
        let exists = false;
        let devices_camera = [];
        let devices_mic = [];
        var _stream = null;
        let devices_cam_id = []
        let devices_mic_id = []

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == 'videoinput') {
                if (!devices_cam_id.includes(devices[i].deviceId)) {
                    devices_camera.push(devices[i]);
                    devices_cam_id.push(devices[i].deviceId);

                }
                if (device_local == devices[i].deviceId) {
                    exists = true;
                }
            }
            else if (devices[i].kind == 'audioinput') {
                if (!devices_mic_id.includes(devices[i].deviceId)) {
                    devices_mic.push(devices[i]);
                    devices_mic_id.push(devices[i].deviceId);

                }
            }

        }
        console.log(devices_camera);
        console.log('oi');
        if (devices_camera.length > 0 && devices_mic.length > 0) {
            let use = '';
            if (exists == true) {
                use = device_local;
            }
            else {
                localStorage.setItem("deviceId", devices_camera[0]);
                use = devices_camera[0];
            }
            console.log(use)
            this.props.mudarDevices({ devices_camera: devices_camera, devices_mic, device_select: use })
            navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: use, frameRate: 4,
                    facingMode: "user",
                    width: 320,
                    height: 240
                }, audio: true
            }).
                then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
        }
        else {
            this.setState({ show_solicitar: true, loading_camera: false, msg: 'Não foi reconhecido nenhuma câmera ou microfone conectado em seu computador. Conecte e tente novamente' })
        }
    }

    handleSuccess(stream) {
        // screenshotButton.disabled = false;
        // window.location="/processos_user"
        this.stream = stream;
        this.setState({ show_aceitar: true, loading_camera: false, show_video: true });
        this.videoRef.current.style.display = 'flex';

        this.videoRef.current.srcObject = stream;
        this.videoRef.current.volume = 0;
    }

    aceitar_termos() {
        // this.setState({ loading_camera: true, msg: '' });
        this.props.mudarStream(this.stream);
        this.setState({ redirect: true });
    }

    handleError(stream) {
        console.log(stream);
        let msg = '';
        if (stream.toString().includes('Permission denied')) {
            msg = 'Você bloqueou seu acesso a camera. Para liberá-lo vá nas configurações de seu navegador.';
        }
        else if (stream.toString().includes('Permission dismissed')) {
            msg = 'Aceita as permissões de acesso a câmera para poder avançar.';
        }
        else if (stream.toString().includes('Could not start video source')) {
            msg = 'Não foi possível acessar sua câmera. Conecte uma câmera, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('Could not start audio source')) {
            msg = 'Não foi possível acessar seu microfone. Conecte um microfone, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('NotFoundError')) {
            msg = 'Não foram reconhecidos nenhuma câmera ou microfone conectados em seu computador. Conecte-os e tente novamente.';
        }
        else {
            msg = stream.toString();
        }
        this.setState({ show_solicitar: true, loading_camera: false, stream: null, msg: msg });
    }

    change_device(event) {
        this.props.mudarDevices({ devices_camera: this.props.devices_camera, devices_mic: this.props.devices_mic, device_select: event.target.value })

        // console.log(event);
        localStorage.setItem("deviceId", event.target.value);

        navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: event.target.value, frameRate: 4,
                facingMode: "user",
                width: 320,
                height: 240
            }, audio: true
        }).
            then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}


                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.processo.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>

                            <hr />
                            <div className="row">

                                <div className="col-12 ">
                                    <div className="form-group row">

                                        <div className="col-12 col-md-12" style={{ objectFit: 'contain' }}>


                                            <div style={{ height: '200px' }}>


                                                <div className="project-item" style={{ marginBottom: 0 }}>
                                                    <div className="overlay-container"
                                                        style={{ height: '200px', display: 'flex', justifyContent: 'center' }}>
                                                        {this.props.faculdade.logo != null && <img src={URL + 'storage/' + this.props.faculdade.logo} alt="img"
                                                            style={{ height: '200px', objectFit: 'contain', borderRadius: '10px' }} />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12" dangerouslySetInnerHTML={{ __html: this.props.faculdade.termos }}>

                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    {this.state.show_video == true && (this.props.processo.type_fiscalization=='all' || this.props.processo.type_fiscalization=='image') && <div id="video_show" >

                                        <video autoPlay muted style={{ height: '300px', display: 'none' }} id="video" ref={this.videoRef}></video>
                                        <div className="form-group" style={{ marginTop: '1rem' }}>
                                            <select value={this.props.device_select} className="custom-select" id="cameras" onChange={this.change_device.bind(this)}>
                                                {this.props.devices_camera.map((item, id) => (
                                                    <option key={id} value={item.deviceId}>{item.label}</option>

                                                ))}
                                            </select>
                                            <ChangeCamModal />
                                        </div>
                                    </div>}

                                </div>
                                <div className="col-12">
                                    ´<p style={{ textAlign: 'center', color: 'red', fontSize: '1rem' }} id="msg">{this.state.msg}</p>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center" id="button_div">
                                    {this.state.show_solicitar == true && (this.props.processo.type_fiscalization=='all' || this.props.processo.type_fiscalization=='image' ) && <button type="button" onClick={() => this.solicitar_camera()} className="btn btn-success btn-lg waves-effect waves-light"

                                        style={{ width: '100%' }}>Solicitar permissões</button>}
                                    {this.state.loading_camera == true && <div id="loading">
                                        <div className="spinner-border text-primary" role="status"  >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>}
                                    {(this.state.show_aceitar == true || this.props.processo.type_fiscalization=='none' )&&
                                        <button onClick={this.aceitar_termos.bind(this)} type="button" id="aceitar" className="btn btn-success btn-lg waves-effect waves-light" style={{ width: '100%' }}>Aceitar termos e conceder permissões</button>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        devices_camera: state.AppReducer.devices_camera,
        devices_mic: state.AppReducer.devices_mic,
        device_select: state.AppReducer.device_select,
        stream: state.AppReducer.stream,
        videoRef: state.AppReducer.videoRef,

    }
);

export default connect(mapsStateToProps, { mudarFaculdade, mudarUser, mudarStream, mudarDevices, mudarCamModal, logout,mudarProcesso })(Termos);
