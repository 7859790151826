
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout,mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class NaoIniciada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            time_text: '88:88:88'
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.unmont_ = false;

    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {
            // console.log('Tempo');
            // console.log(seconds_diff);

            // console.log('Diff');
            // console.log(diff);

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

            // console.log('Tempo new');
            // console.log(seconds_diff);

        }

        var totalSecs = moment(this.props.processo.inicio, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');

        // console.log($('#inicio_id').val())
        // console.log(moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'))
        // var totalSecs = tempo - iniciado;
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;

        // totalSecs--;
        if (document.getElementById('timer') == null) {
            return;
        }
        document.getElementById('timer').innerHTML = currentHours + ":" + currentMinutes + ":" + currentSeconds;

        if (totalSecs <= 0) {
            totalSecs = 0;

            this.setState({ time_text: currentHours + ":" + currentMinutes + ":" + currentSeconds });
            // location.reload();
            this.props.get_processo();
        }
        else {

            this.setState({ time_text: currentHours + ":" + currentMinutes + ":" + currentSeconds });

            this.timer_timeout =  setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    componentDidMount() {
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        this.incTimer();
        this.logs_timeout = setTimeout(() => {
            this.salvar_log();
        }, 30000);
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        clearTimeout(this.timer_timeout);
        this.unmont_=true;

    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=not_initial&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    if(resp.processo!=null && resp.processo.full_screen!=this.props.processo.full_screen){
                        this.props.mudarProcesso(resp.processo);
                    }
                    if (this.unmont_ == false) {

                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }



    render() {

        return (
            <div >

                <hr />
                <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Tempo para começar:</p>
                </div>
                <div id='timer'
                    style={{ color: 'black', padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>

                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        token: state.AppReducer.token,

    }
);

export default withRouter(connect(mapsStateToProps, { logout ,mudarProcesso})(NaoIniciada));


