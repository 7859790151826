
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, changeState,mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';
import { jsPDF } from 'jspdf';

class Finalizada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
        };
        this.unmont_ = false;

    }


    componentDidMount() {
        this.props.changeState({ stream: null });
        // this.props.object_worker.terminate();
        this.logs_timeout = setTimeout(() => {
            this.salvar_log();
        }, 30000);
    }

    componentWillUnmount() {
        this.unmont_=true;

        clearTimeout(this.logs_timeout);
    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=finish&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if(resp.processo!=null && resp.processo.full_screen!=this.props.processo.full_screen){
                        this.props.mudarProcesso(resp.processo);
                    }
                    if (this.unmont_ == false) {

                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }

    logout() {
        localStorage.removeItem('token');
        // this.setState({redirect:true,path:'/'});
        this.props.logout();
    }


    render() {

        return (
            <div >

                <hr />
                <div className="text-success"
                    style={{ padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>
                    Avaliação finalizada com sucesso
                </div>
                <hr />
                {this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div>
                    <h5 style={{ color: 'black' }}>Folha com suas respostas</h5>
                    <p style={{ color: 'black', fontSize: '1rem', marginBottom: '2.2rem' }}>A folha com suas respostas será exibido apenas uma vez. Após sair ou atualizar a página, não será possível baixá-la novamente.</p>
                </div>}
                {this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div id="resultados">
                    <div className="row d-flex justify-content-center float-right mr-2">

                        <img src={URL + 'storage/' + this.props.faculdade.logo} alt="" height='60' style={{ height: 40, objectFit: 'contain' }} />
                    </div>
                    <h5 style={{ textAlign: 'center' }}>
                    Folha com suas respostas</h5>
                    <p style={{ textAlign: 'justify', marginBottom: 0 }}>Nome do Candidato: {this.props.user.name}</p>
                    {this.props.user.numero_inscricao != null && <p style={{ textAlign: 'justify' }}>Número de Inscrição: {this.props.user.numero_inscricao}</p>}
                    <p style={{ textAlign: 'justify', marginBottom: 0 }}>Processo: {this.props.processo.name}</p>

                    {this.props.provas.map((prova, id2) => (

                        <div key={id2} className="row">

                            <div className="col-12">


                                <br />
                                <div className="row">

                                    {prova.questoes.map((item, id) => (
                                        <div key={id} className="col-2" style={{ marginTop: '10px' }}>

                                            <a  >
                                                {(prova.respostas[item.id] == undefined) &&
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                        <b>{id + 1})</b>
                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> - </div>
                                                    </div>}
                                                {prova.respostas[item.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{id + 1})</b>
                                                    <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item.alternativas.map((item2, id2) => {
                                                        if (prova.respostas[item.id] == item2.id) {
                                                            return (item2.order == 1 ? 'a)' : item2.order == 2 ? 'b)' : item2.order == 3 ? 'c)' : item2.order == 4 ? 'd)' : item2.order == 5 ? 'e)' : '');
                                                        }
                                                    })} </div></div>}

                                            </a>
                                        </div>
                                    ))}



                                </div>
                                <hr />
                            </div>


                        </div >))}
                </div>}

                {this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    <button className="btn btn-info btn-lg" onClick={async () => {
                        var doc = new jsPDF('l', 'px', 'a4')
                        var width = doc.internal.pageSize.width;
                        var height = doc.internal.pageSize.height;
                        await doc.html('<div style="width:' + width + 'px;padding:10px">' + document.getElementById('resultados').innerHTML + '</div>', {
                            pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' },
                            html2canvas: {
                                // insert html2canvas options here, e.g.
                                width: doc.internal.pageSize.width
                            },
                        });
                        doc.save('suas_respostas.pdf');

                    }} style={{ width: '100%' }}><i
                        className="mdi mdi-download" style={{ color: 'white' }}></i> Baixar folha com suas respostas</button>

                </div>}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    <button className="btn btn-success btn-lg" onClick={this.logout.bind(this)} style={{ width: '100%' }}><i
                        className="mdi mdi-power" style={{ color: 'white' }}></i> Clique aqui para Sair</button>

                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        token: state.AppReducer.token,
        user: state.AppReducer.user,

        // object_worker: state.AppReducer.object_worker,
        provas: state.AppReducer.provas,
        faculdade: state.AppReducer.faculdade,
        processo_resultado: state.AppReducer.processo_resultado,



    }
);

export default withRouter(connect(mapsStateToProps, { logout, changeState,mudarProcesso })(Finalizada));


