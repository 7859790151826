export const login = (data) => (
    {
        type: 'login',
        payload: data
    }
);

export const logout = () => (
    {
        type: 'login',
        payload: ''
    }
);

export const mudarFaculdade = (data) => (
    {
        type: 'faculdade',
        payload: data
    }
);

export const mudarUser = (data) => (
    {
        type: 'user',
        payload: data
    }
);

export const mudarLoadingHome = (data) => (
    {
        type: 'loading_home',
        payload: data
    }
);

export const mudarDevices = (data) => (
    {
        type: 'devices',
        payload: data
    }
);

export const mudarStream = (data) => (
    {
        type: 'stream',
        payload: data
    }
);

export const mudarCamModal = (data) => (
    {
        type: 'change_cam_modal',
        payload: data
    }
);

export const mudarSocket = (data) => (
    {
        type: 'socket',
        payload: data
    }
);

export const mudarComunicadoGeral = (data) => (
    {
        type: 'comunicado_geral',
        payload: data
    }
);

export const mudarSala = (data) => (
    {
        type: 'sala',
        payload: data
    }
);

export const mudarProcesso = (data) => (
    {
        type: 'processo',
        payload: data
    }
);

export const mudarTempoAtual = (data) => (
    {
        type: 'tempo_atual',
        payload: data
    }
);

export const mudarRedacoes = (data) => (
    {
        type: 'redacoes',
        payload: data
    }
);

export const mudarProvas = (data) => (
    {
        type: 'provas',
        payload: data
    }
);

export const mudarCategorias = (data) => (
    {
        type: 'categorias',
        payload: data
    }
);

export const mudarMensagens = (data) => (
    {
        type: 'mensagens',
        payload: data
    }
);

export const mudarAvisoLinhas = (data) => (
    {
        type: 'ultrapassou_linhas',
        payload: data
    }
);


export const mudarWorker = (data) => (
    {
        type: 'worker',
        payload: data
    }
);

export const mudarObjectWorker = (data) => (
    {
        type: 'object_worker',
        payload: data
    }
);

export const mudarFotosUsuario = (data) => (
    {
        type: 'fotos_usuario',
        payload: data
    }
);

export const changeState = (data) => (
    {
        type: 'change_state',
        payload: data
    }
);

// export const get_camera = ()=> dispatch => {

//     var devices = await navigator.mediaDevices.enumerateDevices();
//     let device_local = localStorage.getItem("deviceId")
//     console.log(devices);
//     let exists = false;
//     let devices_camera = [];
//     let devices_mic = [];
//     var _stream = null;
//     let devices_cam_id = []
//     let devices_mic_id = []

//     for (let i = 0; i < devices.length; i++) {
//         if (devices[i].kind == 'videoinput') {
//             if (!devices_cam_id.includes(devices[i].deviceId)) {
//                 devices_camera.push(devices[i]);
//                 devices_cam_id.push(devices[i].deviceId);

//             }
//             if (device_local == devices[i].deviceId) {
//                 exists = true;
//             }
//         }
//         else if (devices[i].kind == 'audioinput') {
//             if (!devices_mic_id.includes(devices[i].deviceId)) {
//                 devices_mic.push(devices[i]);
//                 devices_mic_id.push(devices[i].deviceId);

//             }
//         }

//     }
//     console.log(devices_camera);
//     console.log('oi');
//     if (devices_camera.length > 0 && devices_mic.length > 0) {
//         let use = '';
//         if (exists == true) {
//             use = device_local;
//         }
//         else {
//             localStorage.setItem("deviceId", devices_camera[0]);
//             use = devices_camera[0];
//         }
//         console.log(use)
//         dispatch({
//             type:'devices',
//             payload:{ devices_camera: devices_camera, devices_mic, device_select: use }
//         })
//         navigator.mediaDevices.getUserMedia({ video: { deviceId: use }, audio: true }).
//             then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
//     }
//     else {
//         // this.setState({ show_solicitar: true, loading_camera: false, msg: 'Não foi reconhecido nenhuma câmera ou microfone conectado em seu computador. Conecte e tente novamente' })
//     }
// }

