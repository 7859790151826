
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import { logout, mudarProcesso, changeState } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { URL } from '../variables';
import Prova from './Prova';
import Redacao from './Redacao';
import JSZip from 'jszip';
import Chat from './Chat';

class AvaliacaoAndamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            show_warning: false,
            loading_finish: false,
            tab: 0,
            title_warning: '',
            show_warning_page: false,
            last_warning: moment()
        };
        this.canvas = React.createRef();
        this.videoRef = React.createRef();
        this.videodata = []
        this.options = {
            mimeType: 'video/webm;codecs=h264',
            audioBitsPerSecond: 4000,
            videoBitsPerSecond: 4000,
            bitsPerSecond: 4000
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');

        this.time_text = '88:88:88';
    }



    componentDidUpdate(prevProps) {
        // console.log('new');
        // console.log(prevProps.stream);
        // console.log(this.props.stream);

        if (this.props.stream != null && this.props.stream.id != prevProps.stream.id) {
            this.videoRef.current.srcObject = this.props.stream;
        }


    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

        }

        var totalSecs = moment(this.fim, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;
        if (document.getElementById('timer') == null) {
            return;
        }
        if (totalSecs <= 0) {
            totalSecs = 0;
            this.time_text = "00:00:00";
            document.getElementById('timer').innerHTML = 'Tempo restante: 00:00:00';

            this.props.get_processo();
        }
        else {
            this.time_text = currentHours + ":" + currentMinutes + ":" + currentSeconds;
            document.getElementById('timer').innerHTML = 'Tempo restante: ' + currentHours + ":" + currentMinutes + ":" + currentSeconds;
            // console.log('Tempo restante: '+currentHours + ":" + currentMinutes + ":" + currentSeconds);
            // console.log(this.time_text);

            this.timer_timeout = setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    send_status() {
        if (this.videoRef.current == null) {
            return;
        }
        if (this.props.socket != null) {
            this.props.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            setTimeout(() => {
                this.send_status();
            }, 320000);
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    salvar_log(event) {
        let event_m = '';

        if (event.type == 'blur') {
            if (moment().diff(this.state.last_warning, 'seconds') > 30) {
                this.setState({ show_warning_page: true, last_warning: moment() });
            }
            event_m = 'desfocou';
            if (this.props.processo.type_fiscalization == 'all') {

                this.props.socket.emit("send_warning_behavior", { msg: 'O candidato saiu da página da avaliação', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id });
            }

        }
        else {
            event_m = 'focou';
            if (this.props.processo.type_fiscalization == 'all') {
                this.props.socket.emit("send_warning_behavior", { msg: 'O candidato voltou para a página da avaliação', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id });
            }
        }

        fetch(`${URL}api/salvar_log?screen=${event_m}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if(resp.processo!=null && resp.processo.full_screen!=this.props.processo.full_screen){
                        this.props.mudarProcesso(resp.processo);
                    }
                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.salvar_log_bind = this.salvar_log.bind(this);
        window.addEventListener('focus', this.salvar_log_bind);
        window.addEventListener('blur', this.salvar_log_bind);
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        this.fim = this.props.processo.fim;
        console.log('INICIOU TIMER');
        this.incTimer();
        if (this.props.processo.type_fiscalization == 'all') {

            this.videoRef.current.srcObject = this.props.stream;



            this.mediaRecorder = new MediaRecorder(this.props.stream, this.options);
            this.mediaRecorder.start();
            this.mediaRecorder.ondataavailable = function (e) {
                this.videodata.push(e.data);
            }.bind(this);
            let id_stream = this.props.stream.id;
            this.mediaRecorder.onstop = async function (e) {
                this.salvar_dados(id_stream);
            }.bind(this);
            setTimeout(() => {
                if (this.mediaRecorder.state != 'inactive') {
                    this.mediaRecorder.stop();
                }
            }, 30000);


            setTimeout(() => {
                this.send_status();
            }, 320000);

            
        }
        else if(this.props.processo.type_fiscalization == 'image'){
            // this.timeout_save_logs_and_wording = setTimeout(() => {
            //     this.save_logs_and_wording();
            // }, 30000);
            this.timer_save=this.props.processo.save_timer;
            setTimeout(() => {
                this.send_status();
            }, 320000);

            this.videoRef.current.srcObject = this.props.stream;

            this.timeout_save_logs_and_wording = setTimeout(() => {
                this.save_logs_and_wording();
            }, this.timer_save);
        }
        else{
            this.timer_save=this.props.processo.save_timer;

            this.timeout_save_logs_and_wording = setTimeout(() => {
                this.save_logs_and_wording();
            }, this.timer_save);
        }
        setTimeout(() => {
            this.get_time_server();
        }, 30000);

    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.salvar_log_bind);
        window.removeEventListener('blur', this.salvar_log_bind);
        clearTimeout(this.timer_timeout);
        clearTimeout(this.timeout_save_logs_and_wording);
    }


    get_time_server() {
        if (this.videoRef.current == null) {
            return;
        }
        fetch(`${URL}api/get_time_server`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else {
                    if (resp.atual != null) {
                        this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    }
                }
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            } catch (err) {
                console.log(err);
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            }

        })
            .catch((err) => {
                console.log(err);
                setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            });
    }

    save_logs_and_wording() {
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }

        let form = new FormData();
        if (redacao_ids.length > 0) {
            for (let i = 0; i < redacao_ids.length; i++) {
                form.append('textos[]', textos[i]);
                form.append('titulos[]', titulos[i]);
                form.append('redacao_ids[]', redacao_ids[i]);
            }
        }
        form.append('processo_id', this.props.processo.id);

        if((this.props.processo.type_fiscalization=='image')){
            this.canvas.current.height = document.getElementById('video_oficial').videoHeight;
            this.canvas.current.width = document.getElementById('video_oficial').videoWidth;
            this.canvas.current.getContext('2d').drawImage(document.getElementById('video_oficial'), 0, 0);
            form.append('foto', this.canvas.current.toDataURL('image/jpeg', 0.15));
        }


        fetch(`${URL}api/save_logs_and_wording`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null });
                    }
                    else {
                        if (this.props.processo.fim != resp.processo.fim) {
                            this.props.changeState({ processo: resp.processo });
                            this.fim = resp.processo.fim;
                        }

                        this.timeout_save_logs_and_wording = setTimeout(() => {
                            this.save_logs_and_wording();
                        }, this.timer_save);
                    }

                    
                }




            } catch (err) {
                console.log(err);
                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording();
                }, this.timer_save);

            }

        })
            .catch((err) => {

                console.log(err);
                this.timeout_save_logs_and_wording = setTimeout(() => {
                    this.save_logs_and_wording();
                }, this.timer_save);
                // this.props.mudarLoadingHome(false);
            });
    }


    async salvar_dados(id_stream) {
        if (this.videoRef.current == null) {
            return;
        }
        console.log('Diferença stream: ', this.props.stream.id != id_stream)
        if (this.props.stream.id != id_stream) {
            console.log('hi');
            this.mediaRecorder = new MediaRecorder(this.props.stream, this.options);
            this.mediaRecorder.ondataavailable = function (e) {
                this.videodata.push(e.data);
            }.bind(this);
            id_stream = this.props.stream.id;
            this.mediaRecorder.onstop = async function (e) {
                this.salvar_dados(id_stream);
            }.bind(this);
        }
        this.mediaRecorder.start();

        setTimeout(() => {
            if (this.mediaRecorder.state != 'inactive') {
                this.mediaRecorder.stop();
            }

        }, 30000);


        // this.mediaRecorder.requestData();

        this.canvas.current.height = document.getElementById('video_oficial').videoHeight;
        this.canvas.current.width = document.getElementById('video_oficial').videoWidth;
        // console.log(this.canvas.current.width,this.canvas.current.height)
        this.canvas.current.getContext('2d').drawImage(document.getElementById('video_oficial'), 0, 0);
        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg',0.15));
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }

        console.log(redacao_ids);

        let provas = {};

        for (let i = 0; i < this.props.provas.length; i++) {
            if (provas[this.props.provas[i].prova.id] == undefined) {
                provas[this.props.provas[i].prova.id] = {}
            }
            let questoes = Object.keys(this.props.provas[i].respostas);
            for (let j = 0; j < questoes.length; j++) {
                provas[this.props.provas[i].prova.id][questoes[j]] = this.props.provas[i].respostas[questoes[j]];
            }
        }
        let date_tz = moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD_HH-mm-ss');
        let form = new FormData();
        if (redacao_ids.length > 0) {
            for (let i = 0; i < redacao_ids.length; i++) {
                form.append('textos[]', textos[i]);
                form.append('titulos[]', titulos[i]);
                form.append('redacao_ids[]', redacao_ids[i]);
            }
        }

        form.append('tempo', this.time_text);
        form.append('tab', this.state.tab);
        form.append('processo_id', this.props.processo.id);
        form.append('profile', this.canvas.current.toDataURL('image/jpeg', 0.15));
        form.append('date', date_tz);
        form.append('scroll_position', window.scrollY);
        form.append('width_window', window.innerWidth);
        form.append('height_window', window.innerHeight);
        form.append('provas', JSON.stringify(provas));
        let recordedBlob = new Blob(this.videodata, { type: "video/webm;codecs=h264" });
        const jsZip = new JSZip();
        jsZip.file('video_' + date_tz + '.webm', recordedBlob);
        let screenCaptureZipFile = await jsZip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 9
            }
        });
        form.append('video', screenCaptureZipFile);

        this.videodata = [];


        fetch(`${URL}api/salvar_dados`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else if (resp.processo == null) {
                        this.props.changeState({ processo: null, stream: null });
                    }
                    else {
                      
                        if (resp.processo!=null && (resp.processo.full_screen!=this.props.processo.full_screen || this.props.processo.fim != resp.processo.fim)) {
                            this.props.changeState({ processo: resp.processo });
                            this.fim = resp.processo.fim;
                        }
                    }
                }




            } catch (err) {
                console.log(err);


            }

        })
            .catch((err) => {

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    async finalizar_avaliacao() {

        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg',0.15));
        let textos = [];
        let titulos = [];
        let redacao_ids = [];
        for (let i = 0; i < this.props.redacoes.length; i++) {
            textos.push(this.props.redacoes[i].resultado.texto);
            titulos.push(this.props.redacoes[i].resultado.titulo);
            redacao_ids.push(this.props.redacoes[i].redacao.id);
        }


        fetch(`${URL}api/finalizar_avaliacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                titulo: titulos,
                redacao_id: redacao_ids,
                texto: textos,
                processo_id: this.props.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.props.socket.emit("finish_event", { sala_id: this.props.sala.id, aluno_id: this.props.user.id, finish:true,  faculdade_id: this.props.faculdade.id });

                    this.props.aceitar_regras(resp);
                }
            } catch (err) {
                this.setState({ loading_finish: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_finish: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    render() {
        let index = 0;
        let ultrapassou_flag = Object.values(this.props.ultrapassou_linhas).includes(true) ? true : false;
        return (
            <div >
                <canvas ref={this.canvas} id="background-canvas-andamento" style={{ position: 'absolute', top: '-99999999px', left: '-9999999999px' }}></canvas>
                <video ref={this.videoRef} muted autoPlay id="background-canvas" style={{ height: '100px', position: 'absolute', top: 200, zIndex: -1 }}></video>
                {/* <FaceDetection /> */}
                {<div id='timer' hidden={this.props.processo.type_fiscalization == 'none'}
                    style={{ position: 'fixed', top: 200, right: 50, backgroundColor: 'white', fontSize: '1rem', color: 'black', zIndex: 1000, padding: '5px 5px 5px 5px' }}>

                </div>}
                <div className="float-right">
                    {this.state.loading_finish == false && <button onClick={() => {
                        let achou = false;
                        let achou_redacao=false
                        for (let i = 0; i < this.props.provas.length; i++) {
                            for (let j = 0; j < this.props.provas[i].questoes.length; j++) {
                                if (this.props.provas[i].respostas[this.props.provas[i].questoes[j].id] == undefined) {
                                    achou = true;
                                    break;
                                }
                            }
                            if (achou == true) {
                                break;
                            }
                        }

                        for (let i = 0; i < this.props.redacoes.length; i++) {
                            let redacao = this.props.redacoes[i];
                            if(redacao.resultado.texto==null || redacao.resultado.texto.trim().length==0){
                                achou_redacao = true;
                                break
                            }
                        }
                        if (achou == true) {
                            this.setState({ show_warning: true, title_warning: 'Você tem certeza que quer finalizar a avaliação? Existem questões na prova não respondidas.' })
                        }
                        else if(achou_redacao==true){
                            this.setState({ show_warning: true, title_warning: 'Você tem certeza que quer finalizar a avaliação? A redação não foi respondida.' })

                        }
                        else {
                            this.setState({ show_warning: true, title_warning: 'Você tem certeza que quer finalizar a avaliação?' })
                        }
                    }} type="button" id="finalizar" disabled={ultrapassou_flag} className="btn btn-success btn-lg waves-effect waves-light"
                        style={{
                            width: '100%', background: ultrapassou_flag == false ? 'indianred' : 'grey',
                            border: ultrapassou_flag == false ? '1px solid indianred' : '1px solid grey'
                        }}>Encerrar e entregar a avaliação</button>}
                    {this.state.loading_finish == true && <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                </div>
                {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization=='image') && <Chat />}
                <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.tab}
                    onSelect={(k) => this.setState({ tab: k })}
                >
                    {this.props.provas.map((item, id) => {
                        index += 1;
                        return (
                            <Tab tabClassName={'tab'} key={id} eventKey={index - 1} title={item.prova.name}>
                                <Prova indice={id} />
                            </Tab>
                        );

                    })}
                    {this.props.redacoes.map((item, id) => {
                        index += 1;

                        return (
                            <Tab tabClassName={'tab'} key={id} eventKey={index - 1} title={item.redacao.name}>
                                {/* <p>oi</p> */}
                                <Redacao indice={id} />

                            </Tab>
                        );
                    })}
                </Tabs>
                <SweetAlert
                    warning
                    title={this.state.title_warning}
                    onConfirm={this.onConfirm.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo finalizar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao finalizar você não poderá mais realizar alterações na redação e na prova
                </SweetAlert>
                <SweetAlert
                    warning
                    title={'Aviso!'}
                    onConfirm={() => this.setState({ show_warning_page: false })}
                    show={this.state.show_warning_page}
                    confirmBtnText='Entendi'
                    confirmBtnBsStyle="danger"
                >
                    Foi registrado que você saiu da página de avaliação
                </SweetAlert>

            </div >

        );
    }

    onConfirm() {
        this.setState({ show_warning: false, loading_finish: true });
        this.finalizar_avaliacao();
        // this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg', 0.15));
    }

    onCancel() {
        this.setState({ show_warning: false });
    }




}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        faculdade: state.AppReducer.faculdade,
        stream: state.AppReducer.stream,
        token: state.AppReducer.token,
        provas: state.AppReducer.provas,
        redacoes: state.AppReducer.redacoes,
        socket: state.AppReducer.socket,
        user: state.AppReducer.user,
        sala: state.AppReducer.sala,
        ultrapassou_linhas: state.AppReducer.ultrapassou_linhas

    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarProcesso, changeState })(AvaliacaoAndamento));


