
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { mudarSocket, mudarComunicadoGeral, mudarMensagens, mudarStream } from '../actions/AppActions';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { URL_SOCKET,TURN_1,TURN_2,TURN_3,TURN_4 } from '../variables';

// users

class ConexaoProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            mensagens_notificacao: {}
        };
        this.list_peers = {};
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:'+TURN_1+':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:'+TURN_2+':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:'+TURN_3+':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:'+TURN_4+':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                }

            ],
            sdpSemantics: 'unified-plan'
        };
    }

    onIceCandidate(peer, evt, admin_id) {
        if (evt.candidate) {
            this.socket.emit("candidate_to_admin", JSON.stringify({ "candidate": evt.candidate, admin_id: admin_id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id }));
        }
    }

    create_peer(id) {
        if (this.list_peers[id] != undefined) {
            this.list_peers[id].close();
        }
        this.list_peers[id] = new RTCPeerConnection(this.configuration);

        this.list_peers[id].onicecandidate = function (evt) {
            if (!evt.candidate) return;
            // console.log("onicecandidate called");
            this.onIceCandidate(this.list_peers[id], evt, id);
        }.bind(this);
        this.list_peers[id].onaddstream = function (evt) {
            // console.log("onaddstream called");
            // document.getElementById("remoteview").srcObject = evt.stream;
        }.bind(this);
        // list_peers[id].oniceconnectionstatechange = function() {
        //     console.log(list_peers[id].iceConnectionState);
        // }
        // list_peers[id].onconnectionstatechange = function() {
        //     console.log(list_peers[id].connectionState);
        // }
        this.list_peers[id].addStream(this.props.stream);

        // for (const track of this.props.stream.getTracks()) {
        //     list_peers[id].addTrack(track, this.props.stream);
        // }   

        this.list_peers[id].createOffer().then(function (desc) {
            try {
                // console.log('sdp coonect');
                desc.sdp = this.handle_offer_sdp(desc)
                console.log('peer');

                this.list_peers[id].setLocalDescription(new RTCSessionDescription(desc)).then(function (s) {
                    // console.log(s);
                }).catch(function (e) {
                    // console.log(e);
                    this.list_peers[id].close();
                    this.create_peer(id);
                });

                this.socket.emit("sdp", JSON.stringify({ "sdp": desc, admin_id: id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id }));
            }
            catch (err) {
                // this.create_peer(id);
                console.log(err);
            }

        }.bind(this)).catch(function (e) {
            // console.log(e);
        });
    }


    handle_offer_sdp(offer) {
        let sdp = offer.sdp.split('\r\n');//convert to an concatenable array
        let new_sdp = '';
        let position = null;
        sdp = sdp.slice(0, -1); //remove the last comma ','
        for (let i = 0; i < sdp.length; i++) {//look if exists already a b=AS:XXX line
            if (sdp[i].match(/b=AS:/)) {
                position = i; //mark the position
            }
        }
        if (position) {
            sdp.splice(position, 1);//remove if exists
        }
        for (let i = 0; i < sdp.length; i++) {
            if (sdp[i].match(/m=video/)) {//modify and add the new lines for video
                new_sdp += sdp[i] + '\r\n' + 'b=AS:' + '24' + '\r\n';
            }
            else {
                if (sdp[i].match(/m=audio/)) { //modify and add the new lines for audio
                    new_sdp += sdp[i] + '\r\n' + 'b=AS:' + 48 + '\r\n';
                }
                else {
                    new_sdp += sdp[i] + '\r\n';
                }
            }
        }
        return new_sdp; //return the new sdp
    }

    video_init() {

        this.socket = io.connect(URL_SOCKET, { "transports": ['websocket'],extraHeaders: { Authorization: `Bearer ${this.props.token}` } });
        this.props.mudarSocket(this.socket);


        //Send the ICE Candidate to the remote peer

        console.log(this.props.user);
        //Communications with the remote peer through signaling server
        this.socket.on("connect", function (client) {
            //Connection established with the signaling server
            // console.log("connected!");
            this.socket.emit("join", { name: 'Aluno', sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            // this.socket.emit('get_list_admins', { sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
        }.bind(this));

        this.socket.on("get_list_admins" + this.props.user.id, function (lista) {
            console.log(lista);
            if(this.props.processo.type_fiscalization=='all'){
                for (let i = 0; i < lista.length; i++) {
                    this.create_peer(lista[i]);
                }
            }
        }.bind(this));
        this.socket.on("join_stream_admin" + this.props.sala.id, function (data) {
            let id = data.admin_id;
            if(this.props.processo.type_fiscalization=='all'){
                this.create_peer(id);
            }

        }.bind(this));
        //Listening for the candidate message from a peer sent from onicecandidate handler
        this.socket.on("candidate", function (msg) {
            // console.log("candidate received");
            // console.log(msg);
            if (this.list_peers[JSON.parse(msg).admin_id] != undefined && this.list_peers[JSON.parse(msg).admin_id].connectionState != 'closed') {
                this.list_peers[JSON.parse(msg).admin_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
            }
        }.bind(this));


        //Listening for answer to offer sent to remote peer
        this.socket.on("answer" + this.props.user.id, function (answer) {
            // console.log("answer received");
            // console.log(answer);

            this.list_peers[JSON.parse(answer).admin_id].setRemoteDescription(new RTCSessionDescription(JSON.parse(answer).sdp));
        }.bind(this));

        this.socket.on('disconnect', function () {
            // console.log('desconectou')
        }.bind(this));

        // this.socket.on('welcome', function (data) {
        //     me = data.id;
        // });
        this.socket.on("send_msg" + this.props.user.id, function (data) {
            console.log(data);
            // $('#body_msg').append(template_receive.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, data.id_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            // $('#msg_notifi').append(template_msg_notifi.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'not' + data.id_msg));
            // setTimeout(() => {
            //     $('#not' + data.id_msg).remove();
            // }, 4000);
            let mensagens = [...this.props.mensagens];
            mensagens.push({
                receive: { id: this.props.user.id, name: this.props.user.name, }, receive_id: this.props.user.id,
                sender_id: data.id_adm, sender: { id: data.id_adm, name: data.nome_chat }, sala_id: data.sala_id, uuid: data.id_msg, visto: true, message: data.msg
            });
            let mensagens_notificacao = { ...this.state.mensagens_notificacao };
            mensagens_notificacao[data.id_msg] = { msg: data.msg, name: data.nome_chat, id: data.id_msg };
            this.setState({ mensagens_notificacao });
            // $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
            setTimeout(() => {
                this.removeNotificacao(data.id_msg);
            }, 15000);
            this.props.mudarMensagens(mensagens);
            this.socket.emit('msg_receive', { id_msg: data.id_msg, sala_id: this.props.sala.id, aluno_id: this.props.user.id, admin_id: data.admin_id, faculdade_id: this.props.faculdade.id });
        }.bind(this));

        this.socket.on("msg_receive_aluno" + this.props.user.id, function (data) {
            let achou = false;
            let mensagens = [...this.props.mensagens];
            for (let i = 0; i < mensagens.length; i++) {
                if (achou == false) {
                    mensagens[i].visto = true;
                }
                if (mensagens[i].uuid == data.uuid) {
                    achou = true;
                    break;
                }
            }
            this.props.mudarMensagens(mensagens);
        }.bind(this));

        this.socket.on("msg_save_aluno" + this.props.user.id, function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check');
            console.log(data);
            let mensagens = [...this.props.mensagens];
            for (let i = 0; i < mensagens.length; i++) {
                if (mensagens[i].uuid == data.id_msg) {
                    mensagens[i].visto = false;
                    break;
                }
            }
            this.props.mudarMensagens(mensagens);
        }.bind(this));

        this.socket.on("comunicado_geral" + this.props.faculdade.id, function (data) {
            let id_msg_comunicado = this.uuidv4();
            let comunicados = { ...this.props.comunicado_geral };
            comunicados[id_msg_comunicado] = { msg: data.msg, name: data.nome_chat, id: id_msg_comunicado };
            this.props.mudarComunicadoGeral(comunicados);
            // $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
            setTimeout(() => {
                this.removeComunicado(id_msg_comunicado);
            }, 15000);
        }.bind(this));
    }

    removeComunicado(id_msg_comunicado) {
        let comunicados = { ...this.props.comunicado_geral };
        delete (comunicados[id_msg_comunicado]);
        this.props.mudarComunicadoGeral(comunicados);
    }

    removeNotificacao(id_msg) {
        let mensagens_notificacao = { ...this.state.mensagens_notificacao };
        delete (mensagens_notificacao[id_msg]);
        this.setState({ mensagens_notificacao });
    }

    componentDidMount() {
        this.video_init();
    }

    componentDidUpdate(prev) {
        // console.log(prev.stream);
        // console.log(this.props.stream);
        if (prev.stream!=null && this.props.stream != null && this.props.stream.id!=prev.stream.id) {
            // this.videoRef.current.srcObject = this.props.stream
            let lista = Object.keys(this.list_peers);
            console.log(lista);
            
            for (let i = 0; i < lista.length; i++) {
                // this.create_peer(lista[i]);
                this.list_peers[lista[i]].getSenders().map(async(sender) =>await
                        sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind)))
            }
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    componentWillUnmount() {
        this.socket.close();
        this.props.mudarSocket(null);
        if (this.props.stream != null) {
            this.props.stream.getTracks().forEach(track => track.stop());
        }
        this.props.mudarStream(null);
        for (let i = 0; i < Object.keys(this.list_peers).length; i++) {
            this.list_peers[Object.keys(this.list_peers)[i]].close();
        }

    }


    render() {
        let comunicado_geral = Object.values(this.props.comunicado_geral);
        return (
            <div>
                <div style={{ position: 'fixed', bottom: '50px', left: '10px', zIndex: 10000000, width: '300px', display: 'flex', flexDirection: 'column-reverse', whiteSpace: 'pre-wrap' }}
                    id="msg_notifi_comunicado">
                    {comunicado_geral.map((item, id) => (
                        <div key={item.id} className="alert alert-info bg-info text-white border-0 alert-dismissible fade show" role="alert">
                            <button onClick={() => this.removeComunicado(item.id)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>{item.name}</strong>
                            <p style={{ margin: 0 }}>{item.msg}</p>
                        </div>
                    ))}

                </div>
                <div style={{ position: 'fixed', bottom: '50px', right: '10px', zIndex: 10000000, width: '300px', display: 'flex', flexDirection: 'column-reverse', whiteSpace: 'pre-wrap' }}
                    id="msg_notifi">
                    {Object.values(this.state.mensagens_notificacao).map((item, id) => (

                        <div key={id} className="alert alert-success bg-success text-white border-0 alert-dismissible fade show" role="alert">
                            <button onClick={() => this.removeNotificacao(item.id)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>{item.name}</strong>
                            <p style={{ margin: 0 }}>{item.msg}</p>
                        </div>
                    ))}



                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade,
        sala: state.AppReducer.sala,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        comunicado_geral: state.AppReducer.comunicado_geral,
        stream: state.AppReducer.stream,
        mensagens: state.AppReducer.mensagens,
    }
);

export default connect(mapsStateToProps, { mudarSocket, mudarComunicadoGeral, mudarMensagens, mudarStream })(ConexaoProcesso);


