
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout,mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto:'',
            chat_open:false
        };
        this.messages_view=React.createRef();
    }



    componentDidMount() {
        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

   
    componentDidUpdate(){
        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    send_message(){
        let uid_msg=this.uuidv4();
        let msg = this.state.texto;
        if(msg!=null && msg!='' && msg.replace(/ /g,'')!=''){
            this.props.socket.emit("send_msg_fiscal", {msg:msg,sala_id:this.props.sala.id,aluno_id:this.props.user.id,nome_chat:this.props.user.name,id_msg:uid_msg,faculdade_id:this.props.faculdade.id});
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            let mensagens = [...this.props.mensagens];
            mensagens.push({sender:{id:this.props.user.id,name:this.props.user.name,},sender_id:this.props.user.id,
                receive_id:null,sala_id:this.props.sala.id,uuid:uid_msg,visto:undefined,message:msg});
            this.props.mudarMensagens(mensagens);
            this.setState({texto:''});
            
        }
    }



    render() {

        return (
            <div
                style={{
                    background: 'white', height: '440px', width: '300px',
                    position: 'fixed',
                    bottom: this.state.chat_open==false?'-400px':0,
                    right: '15px',
                    zIndex: 2000, wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">

                <a id="close_chat"
                    onClick={()=>{
                        this.setState({chat_open:!this.state.chat_open});
                    }}
                    style={{ borderTopLeftRadius:'5px',borderTopRightRadius:'5px', backgroundColor: '#222', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <p style={{ margin: 0, color: 'white', textAlign: 'center' }}>Fiscal</p>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '2rem'
                    }}>
                        <span aria-hidden="true"><i className="mdi mdi-open-in-new"></i></span>
                    </button>
                </a>
                <div ref={this.messages_view} style={{ height: '330px', padding: 0, margin: 0 }} className="overflow-auto" id="body_msg">
                    {this.props.mensagens.map((item, id) => {
                        if (item.sender_id == this.props.user.id) {
                            return (
                                <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }} >
                                        <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem' }}>{item.message}</p>
                                        {item.visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                        
                                        {item.visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                        {item.visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                    </div>
                                </div>
                            )
                        }
                        else if (item.receive_id == this.props.user.id) {
                            return (
                                <div key={id} style={{display:'flex',alignItems: 'flex-start',justifyContent:'flex-start',marginTop: '0.5rem',marginBottom:'0.5rem'}}>
                                    <div style={{padding:'5px',backgroundColor: 'azure',marginRight:'6rem',marginLeft:'0.5rem',borderRadius:'5px',textAlign:'justify'}}>
                                        <p style={{marginBottom: 0,color: 'blue',fontWeight: 'bold',fontSize: '0.9rem'}}>Fiscal {item.sender.name}</p>
                                        <p style={{marginBottom: 0,color:'black'}}>{item.message}</p>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                
                {this.props.processo.chat_type =='all' && <div
                    style={{height:'70px',backgroundColor:'#f2f2f2',borderTop:'1px solid lightgrey',padding:'5px',display:'flex'}}>
                        <input autoComplete="off" onKeyPress={(e)=>{
                            let keyCode = e.keyCode || e.which;
                            if(keyCode==13){
                                this.send_message();
                            }
                        }} onChange={(e)=>this.setState({texto:e.target.value})} value={this.state.texto} name="msg" placeholder="Mensagem..." className="form-control msg_input" style={{resize:'none',height:'100%'}} />
                        <div className="send_msg" onClick={()=>{
                            this.send_message();
                        }}
                        style={{display: 'flex',justifyContent: 'center',alignItems: 'center',marginLeft:'5px'}}>
                        <i className="mdi mdi-send" style={{fontSize: '1.4rem'}}></i>

                    </div>
                </div>}

                {this.props.processo.chat_type =='fiscal_to_student' && <div
                    style={{height:'70px',backgroundColor:'#f2f2f2',borderTop:'1px solid lightgrey',padding:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <p style={{marginBottom:0,textAlign:'center',fontSize:15}}>Você não pode enviar mensagens</p>

                </div>}

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        mensagens: state.AppReducer.mensagens,
        socket: state.AppReducer.socket,
        faculdade:state.AppReducer.faculdade
    }
);

export default withRouter(connect(mapsStateToProps, { logout,mudarMensagens })(Chat));


