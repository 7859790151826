
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { changeState, logout } from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../assets/images/users/user-4.jpg';
import moment from 'moment-timezone';
import NaoIniciada from './NaoIniciada';
import { URL } from '../variables';
import AceiteRegras from './AceiteRegras';
import AvaliacaoAndamento from './AvaliacaoAndamento';
import Finalizada from './Finalizada';




class ProcessoUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true,
            finish: false,
            full_screen: false
        };
    }


    exitHandler(e) {
        // console.log(e)
        if (document.webkitIsFullScreen === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if (document.mozFullScreen === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if (document.msFullscreenElement === false) {
            ///fire your event
            this.setState({ full_screen: false });
            this.event_fullscreen('exit_fullscreen');
        }
        else if(document.fullscreenElement) {
            this.setState({ full_screen: true });
            
        }
    }

    openFullscreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen().then(()=>{
                this.setState({ full_screen: true });
            });

        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen().then(()=>{
                this.setState({ full_screen: true });
            });

        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen().then(()=>{
                this.setState({ full_screen: true });
            });

        }
        this.clicked=false;
    }

    solicitar_camera() {
        // this.setState({ loading_camera: true, msg: '', show_solicitar: false });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => this.get_camera()).catch(this.handleError.bind(this));
    }

    async get_camera() {

        var devices = await navigator.mediaDevices.enumerateDevices();
        let device_local = localStorage.getItem("deviceId")
        console.log(devices);
        let exists = false;
        let devices_camera = [];
        let devices_mic = [];
        var _stream = null;
        let devices_cam_id = []
        let devices_mic_id = []

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == 'videoinput') {
                if (!devices_cam_id.includes(devices[i].deviceId)) {
                    devices_camera.push(devices[i]);
                    devices_cam_id.push(devices[i].deviceId);

                }
                if (device_local == devices[i].deviceId) {
                    exists = true;
                }
            }
            else if (devices[i].kind == 'audioinput') {
                if (!devices_mic_id.includes(devices[i].deviceId)) {
                    devices_mic.push(devices[i]);
                    devices_mic_id.push(devices[i].deviceId);

                }
            }

        }
        console.log(devices_camera);
        console.log('oi');
        if (devices_camera.length > 0 && devices_mic.length > 0) {
            let use = '';
            if (exists == true) {
                use = device_local;
            }
            else {
                localStorage.setItem("deviceId", devices_camera[0]);
                use = devices_camera[0];
            }
            console.log(use)
            this.props.changeState({ devices_camera: devices_camera, devices_mic, device_select: use })
            navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: use, frameRate: 4,
                    facingMode: "user",
                    width: 320,
                    height: 240
                }, audio: true
            }).
                then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
        }
        else {
            this.setState({ show_solicitar: true, loading_camera: false, msg: 'Não foi reconhecido nenhuma câmera ou microfone conectado em seu computador. Conecte e tente novamente' })
        }
    }

    handleSuccess(stream) {
        this.props.changeState({ stream });
        // this.get_processo();
        this.aceitar_regras(this.resp);

        // screenshotButton.disabled = false;
        // window.location="/processos_user"

        // this.setState({ show_aceitar: true, loading_camera: false, show_video: true,stream:stream });
        // this.videoRef.current.style.display = 'flex';

        // this.videoRef.current.srcObject = stream;
        // this.videoRef.current.volume = 0;
    }

    handleError(stream) {
        console.log(stream);
        this.setState({ redirect: true });

    }

    componentDidMount() {
        this.clicked=false;
        this.exitHandlerEvent = this.exitHandler.bind(this);
        document.addEventListener('fullscreenchange', this.exitHandlerEvent, false);
        document.addEventListener('mozfullscreenchange', this.exitHandlerEvent, false);
        document.addEventListener('MSFullscreenChange', this.exitHandlerEvent, false);
        document.addEventListener('webkitfullscreenchange', this.exitHandlerEvent, false);

        this.get_processo();

        // if (this.props.stream == null && this.props.processo_resultado!=null && this.props.processo_resultado.status=='I') {
        //     this.setState({ loading_screen: true });

        //     this.solicitar_camera();
        // }
        // else {
        //     this.get_processo();
        // }
    }

    componentWillUnmount() {
        document.removeEventListener('fullscreenchange', this.exitHandlerEvent, false);
        document.removeEventListener('mozfullscreenchange', this.exitHandlerEvent, false);
        document.removeEventListener('MSFullscreenChange', this.exitHandlerEvent, false);
        document.removeEventListener('webkitfullscreenchange', this.exitHandlerEvent, false);
    }

    get_processo() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_processo/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                    setTimeout(() => {
                        this.get_processo();
                    }, 5000);
                }
                else {
                    this.resp = resp;
                    if (resp.processo != null && (resp.processo.type_fiscalization == 'all' || resp.processo.type_fiscalization=='image') && this.props.stream == null && this.props.processo_resultado != null && this.props.processo_resultado.status == 'I') {
                        // this.setState({ loading_screen: true });

                        this.solicitar_camera();
                    }
                    else {
                        this.aceitar_regras(resp);
                    }

                }
            } catch (err) {
                // this.setState({ redirect: true });
                console.log(err);
                setTimeout(() => {
                    this.get_processo();
                }, 5000);
            }

        })
            .catch((err) => {
                // this.setState({ redirect: true });

                console.log(err);
                setTimeout(() => {
                    this.get_processo();
                }, 5000);
            });
    }

    aceitar_regras(resp) {
        if (resp.not_processo == true) {
            this.props.changeState({ processo: null });
            return;
        }
        // this.props.mudarUser(resp.user);
        // this.props.mudarProcesso(resp.processo);
        // this.props.mudarSala(resp.sala);
        // this.props.mudarTempoAtual(resp.tempo_atual);
        // if (this.props.stream == null && resp.finish != true ) {
        //     this.solicitar_camera();
        //     return;
        // }
        let processo_resultado = {};
        if (resp.processo_resultado != null && (this.props.processo_resultado == null || this.props.processo_resultado.status != resp.processo_resultado.status)) {
            processo_resultado.processo_resultado = resp.processo_resultado;
        }
        if (resp.nao_iniciado == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else if (resp.processo.fim != this.props.processo.fim) {
                this.props.changeState({ user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            else {
                this.props.changeState({ user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            this.setState({ loading_screen: false, finish: false, nao_iniciado: true, regras_processo: false, andamento: false, finalizada: false });
        }
        else if (resp.regras_processo == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else if (resp.processo.fim != this.props.processo.fim) {
                this.props.changeState({ user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            else {
                this.props.changeState({ user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            this.setState({ loading_screen: false, finish: false, nao_iniciado: false, regras_processo: true, andamento: false, finalizada: false });
        }
        else if (resp.andamento == true) {
            for (let i = 0; i < resp.redacoes.length; i++) {
                if (resp.redacoes[i].resultado.texto == '' || resp.redacoes[i].resultado.texto == null) {
                    resp.redacoes[i].resultado.texto = '';
                }
                if (resp.redacoes[i].resultado.titulo == null) {
                    resp.redacoes[i].resultado.titulo = '';
                }
                resp.redacoes[i].resultado.texto = "\t" + resp.redacoes[i].resultado.texto;
            }
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado });
            }
            else if (resp.processo.fim != this.props.processo.fim) {
                this.props.changeState({ mensagens: resp.mensagens, redacoes: resp.redacoes, provas: resp.provas, categorias: resp.categorias, user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            else {
                this.props.changeState({ mensagens: resp.mensagens, redacoes: resp.redacoes, provas: resp.provas, categorias: resp.categorias, user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado });
            }
            this.setState({ loading_screen: false, finish: false, nao_iniciado: false, regras_processo: false, andamento: true, finalizada: false });

            // this.props.mudarRedacoes(resp.redacoes);
            // this.props.mudarProvas(resp.provas);
            // this.props.mudarCategorias(resp.categorias);
            // this.props.mudarMensagens(resp.mensagens);

        }
        else if (resp.finish == true) {
            if (resp.processo == null) {
                this.props.changeState({ user: resp.user, processo: null, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            }
            else if (resp.processo.fim != this.props.processo.fim) {
                this.props.changeState({ user: resp.user, processo: resp.processo, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            }
            else {
                this.props.changeState({ user: resp.user, sala: resp.sala, tempo_atual: resp.tempo_atual, ...processo_resultado, provas: resp.provas != null ? resp.provas : [] });
            }
            this.setState({ loading_screen: false, finish: true, nao_iniciado: false, regras_processo: false, andamento: false, finalizada: false });
        }


    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    salvar_log_full_screen(event) {
        let event_m = '';

        if(event=='exit_fullscreen'){
            event_m = 'exit_fullscreen';
            if (this.props.processo.type_fiscalization == 'all') {

                this.props.socket.emit("send_warning_behavior", { msg: 'O candidato saiu da tela cheia', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id });
            }
        }
        else if(event=='enter_fullscreen'){
            event_m = 'enter_fullscreen';
            if (this.props.processo.type_fiscalization == 'all') {

                this.props.socket.emit("send_warning_behavior", { msg: 'O candidato entrou em tela cheia', sala_id: this.props.sala.id, aluno_id: this.props.user.id, nome_chat: this.props.user.name, id_msg: this.uuidv4(), faculdade_id: this.props.faculdade.id });
            }
        }
        else return;
       

        fetch(`${URL}api/salvar_log?screen=${event_m}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    event_fullscreen(e){
        if(this.state.andamento == true && this.state.loading_screen == false && this.props.processo!=null && this.props.processo.full_screen==true){
            this.salvar_log_full_screen(e);                                   
        }
    }




    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.processo.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>


                            <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de início: </b>
                                {moment(this.props.processo.inicio).format('DD/MM/YYYY HH:mm')}
                            </p>

                            <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de encerramento: </b>
                                {moment(this.props.processo.fim).format('DD/MM/YYYY HH:mm')}
                            </p>
                            <hr />
                            {this.state.loading_screen == false && <div>
                                <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', fontSize: '1rem' }}><b style={{ fontWeight: 'bold' }}>Descrição da avaliação: </b>
                                        {"\n"}
                                        {this.props.processo.descricao}
                                    </p>
                                <div hidden={this.state.full_screen == false && this.props.processo.full_screen==true}>
                                  

                                    {this.state.nao_iniciado == true && <NaoIniciada get_processo={this.get_processo.bind(this)} {...this.props} />}
                                    {this.state.regras_processo == true && <AceiteRegras aceitar_regras={this.aceitar_regras.bind(this)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                    {this.state.andamento == true && <AvaliacaoAndamento aceitar_regras={this.aceitar_regras.bind(this)} get_processo={this.get_processo.bind(this)} {...this.props} />}
                                </div>
                                <div>
                                    {this.state.finish == true && <Finalizada get_processo={this.get_processo.bind(this)} {...this.props} />}
                                </div>

                                <div hidden={this.state.full_screen == true || this.state.finish == true || this.props.processo.full_screen==false}>
                                    <button onClick={() => {
                                        if(this.clicked==false){
                                            this.clicked=true;
                                            this.openFullscreen(document.body);
                                            this.event_fullscreen('enter_fullscreen');
                                        }
                                       
                                        // this.setState({ full_screen: true });

                                    }} type="button" id="aceitar" className="btn btn-success btn-lg waves-effect waves-light" style={{ width: '100%' }}>Ver ambiente de avaliação</button>

                                </div>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <h6 style={{ textAlign: 'center' }}>Carregando dados. Por favor, aguarde.</h6>
                            </div>

                            </div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        stream: state.AppReducer.stream,
        processo_resultado: state.AppReducer.processo_resultado,
        // worker: state.AppReducer.worker,
        sala: state.AppReducer.sala,
        faculdade: state.AppReducer.faculdade,

        socket: state.AppReducer.socket,
    }
);

export default connect(mapsStateToProps, { changeState, logout })(ProcessoUser);


