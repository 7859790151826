import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import Home from "./Home/Home";
import SelectFaculdade from "./Auth/select_faculdade";
import Login from "./Auth/Login";
import { useSelector, useDispatch } from 'react-redux'
import Termos from "./Home/termos";
import NotProcesso from "./Home/NotProcesso";
import ProcessoUser from "./Home/ProcessoUser";


const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)
    const processo = useSelector(state => state.AppReducer.processo);
    // const stream = useSelector(state => state.AppReducer.stream);

    return (
        <Route
            {...rest}
            render={props =>
                token != null ? (
                    <Component {...props}>
                        {/* {processo != null && <div>
                            
                        </div>}
                        {/* {processo==null && <OtherRoute path={`${props.match.url}*`}  path_name={`${props.match.url}not_processo`}/>} */}
                    </Component>
                ) : (
                        // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                        // <SelectFaculdade {...props} />
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

                    )
            }
        />)
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <Route
            {...rest}
            render={props =>
                token == null ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
            }
        >

        </Route>);
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Routes = () => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <BrowserRouter>
            <Switch>
                {/* <Route exact path="/" component={(props) => token == null ? <SelectFaculdade {...props} /> : <Home {...props} />} /> */}

                {/* <Route exact path="/:slug/login" component={(props) => isAuthenticated() == false ? <Login {...props} /> : <Route render={props => <Redirect to={{ pathname: "/", state: { from: props.location } }} />}
            />} /> */}
                <LoginRoute exact path="/login" component={(props) => <SelectFaculdade {...props} />} />

                <LoginRoute path="/:slug/login" component={(props) => <Login {...props} />} />
                <PrivateRoute path="/" component={(props) => <Home {...props} />} />

                <OtherRoute path="*" path_name='/' />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;