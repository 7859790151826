import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarProvas, logout,mudarProcesso } from '../actions/AppActions';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

class Prova extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            redirect: false,
            path: '/processo',
            prova: {},
            questoes: [],
            respostas: {},
            resultado: {},
            loading_resposta: {},
            show_success: false,
            msg_success:'',
            show_error: false,
            msg_erro: ''

        }
        this.questoesRef={};
        // this.videoRef=null;
    }


    componentDidMount() {
        let prova = this.props.provas[this.props.indice];
        let respostas = {}
        if (Object.values(prova.respostas).length != 0) {
            respostas = prova.respostas;
        }
        this.setState({ prova: prova.prova, questoes: prova.questoes, respostas: respostas, resultado: prova.resultado });

    }

    salvar_resposta(questao_id, resposta_id,indice,historico) {

        fetch(`${URL}api/salvar_resposta`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prova_id: this.state.prova.id,
                questao_id,
                resposta_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.status==false) {
                    
                    let loading_resposta = { ...this.state.loading_resposta };
                    loading_resposta[questao_id] = false;
                    this.setState({ loading_resposta, msg_erro: resp.msg, show_error: true });
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {
                    let loading_resposta = { ...this.state.loading_resposta };
                    loading_resposta[questao_id] = false;
                    let provas = [...this.props.provas];
                    provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
                    this.props.mudarProvas(provas);
                    
                    this.setState({ loading_resposta,show_success:true,msg_success:'Questão '+indice+') respondida com sucesso' });
                    if(resp.processo==null){
                        this.props.mudarProcesso(null);
                    }
                    else if(resp.processo.fim!=this.props.processo.fim){
                        this.props.mudarProcesso(resp.processo);
                    }

                }




            } catch (err) {
                console.log(err);
                let loading_resposta = { ...this.state.loading_resposta };
                loading_resposta[questao_id] = false;
                let respostas = { ...this.state.respostas };
                respostas[questao_id] = historico;
                this.setState({ respostas,loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão '+indice+'). Por favor, tente novamente', show_error: true });

            }

        })
            .catch((err) => {
                let loading_resposta = { ...this.state.loading_resposta };
                loading_resposta[questao_id] = false;
                let respostas = { ...this.state.respostas };
                respostas[questao_id] = historico;
                this.setState({ respostas,loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão '+indice+'). Por favor, tente novamente', show_error: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    render() {
        let indice = this.props.indice;
        return (
            <div className="row mt-3">
                {this.state.redirect == true && <Redirect to={this.state.path} />}


                <div className="col-12">
                    <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                        {this.state.prova.name}</h5>
                    <hr />
                    <h6 className="mt-0 header-title" style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                        Quais questões foram respondidas?
                            </h6>
                    <div className="row">

                        {this.state.questoes.map((item, id) => (
                            <div key={id} className="col-2" style={{ marginTop: '10px' }}>

                                <a onClick={()=>{
                                    document.body.scrollTo({top:this.questoesRef[item.id].offsetTop+700, behavior: 'smooth'})
                                }} style={{cursor:'pointer'}} className="smooth-goto" >
                                    {(this.props.provas[indice].respostas[item.id] == undefined) && 
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',width:65 }}>
                                        <b>{id + 1})</b>
                                        <div  style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent:'center',alignItems:'center',display:'flex' }}> - </div>
                                        </div>}
                                    {this.props.provas[indice].respostas[item.id] != undefined && <div style={{ display: 'flex',width:65, justifyContent: 'space-between', alignItems: 'center' }}><b>{id + 1})</b>
                                    <div  style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black',justifyContent:'center',alignItems:'center',display:'flex' }}> {item.alternativas.map((item2,id2)=>{
                                        if(this.props.provas[indice].respostas[item.id]==item2.id){
                                            return (item2.order==1?'a)':item2.order==2?'b)':item2.order==3?'c)':item2.order==4?'d)':item2.order==5?'e)':'');
                                        }
                                    })} </div></div>}

                                </a>
                            </div>
                        ))}



                    </div>

                </div >
                <div className="col-12">


                    {this.state.questoes.map((item, id) => (
                        <div ref={(ref)=>this.questoesRef[item.id]=ref} key={id}>
                            <hr style={{ borderTop: '3px solid rgba(0,0,0,.2)' }} />
                            <div className="card">
                                <div className="card-body" style={{
                                    background: '#f8f8f8',
                                    borderRadius: '10px'
                                }}>
                                    <h6 className="mt-0 header-title" style={{ textAlign: 'right' }}>
                                        {item.categoria.name}</h6>
                                    {/* {!!Form::open(['action' =>
                        ['ProcessoUserController@responder_questao'],'data-toggle'=>'validator','files' =>
                        false,'method'=>'post','id'=>$data['prova']->id.'_'.$questao->id]) !!} */}
                                    <div className="row" style={{ fontWeight: 'normal' }}>
                                        <div className="col-2 col-sm-1">
                                            <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                <b>{id + 1})</b></h5>
                                        </div>
                                        <div className="col-10 col-sm-11">

                                            <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                            <form>
                                                {item.alternativas.map((alternativa, id2) => (



                                                    <div key={id2} style={{ color: 'black', textAlign: 'justify' }} className="row">
                                                        <div  className="col-2 pt-3 pb-3" style={{  display: 'flex', alignItems: 'center' }}>

                                                            <input onChange={() => {
                                                                let respostas = { ...this.state.respostas };
                                                                let historico=respostas[item.id];
                                                                respostas[item.id] = alternativa.id;
                                                                this.setState({ respostas },()=>{
                                                                    let loading_resposta = { ...this.state.loading_resposta };
                                                                    loading_resposta[item.id] = true;
                                                                    this.setState({ loading_resposta });
                                                                    this.salvar_resposta(item.id,alternativa.id,id+1,historico);
                                                                });
                                                            }} className="form-check-input" type="radio" name="resposta_id"
                                                            disabled={this.state.loading_resposta[item.id]==true}
                                                                // value="{{$alternativa->id}}"
                                                                style={{
                                                                    position: 'relative', marginLeft: 0, marginTop: 0,
                                                                    marginRight: '10px'
                                                                }} checked={this.state.respostas[item.id] != undefined && this.state.respostas[item.id] == alternativa.id} />
                                                            {alternativa.order == 1 && <b>a) </b>}
                                                            {alternativa.order == 2 && <b>b) </b>}
                                                            {alternativa.order == 3 && <b>c) </b>}
                                                            {alternativa.order == 4 && <b>d) </b>}
                                                            {alternativa.order == 5 && <b>e) </b>}
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="col-10 pt-3 pb-3" style={{  }}>

                                                        </div>
                                                        <div className="col-12">
                                                            <hr />
                                                        </div>
                                                    </div>
                                                ))}
                                            </form>

                                        </div>

                                        <div className="col-12 d-flex justify-content-end mt-2">
                                            {this.state.loading_resposta[item.id] == true && <div className="spinner-border text-primary" role="status">
                                            </div>}
                                            {/* {this.state.loading_resposta[item.id] != true && <button onClick={() => {
                                                if(this.state.respostas[item.id]!=undefined){
                                                    let loading_resposta = { ...this.state.loading_resposta };
                                                    loading_resposta[item.id] = true;
                                                    this.setState({ loading_resposta });
                                                    this.salvar_resposta(item.id,this.state.respostas[item.id]);
                                                }
                                                else{
                                                    this.setState({show_error:true,msg_erro:'Selecione uma alternativa desta questão antes de salvar a resposta'});
                                                }
                                                
                                            }} type="button" id="{{$data['prova']->id}}_{{$questao->id}}_btn_resp"
                                                className="btn btn-success btn-lg waves-effect waves-light responder"
                                                data-id="{{$data['prova']->id}}_{{$questao->id}}">Salvar resposta da questão {id + 1})</button>} */}
                                        </div>


                                    </div>


                                    {/* {!!Form::close() !!} */}

                                </div>
                            </div>
                        </div>))}
                </div>
                <SweetAlert
                    success
                    title={"Respondida!"}
                    onConfirm={()=>this.setState({show_success:false})}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {/* Questão respondida com sucesso */}
                    {this.state.msg_success}

                    </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={()=>this.setState({show_error:false})}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>
            </div>
        );
    }

    onConfirm() {

    }

    onCancel() {

    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        redacoes: state.AppReducer.redacoes,
        provas: state.AppReducer.provas,
    }
);

export default connect(mapsStateToProps, { mudarProvas, logout,mudarProcesso })(Prova);
