
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout,mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { URL } from '../variables';
const axios = require('axios');

class AceiteRegras extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',

            show_warning: false,
            loading_aceite: false,
            show_error: false
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.canvas = React.createRef();
        this.videoRef = React.createRef();
        this.time_text = '88:88:88';
        this.unmont_ = false;
    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

        }

        var totalSecs = moment(this.props.processo.fim, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;
        if (document.getElementById('timer') == null) {
            return;
        }


        if (totalSecs <= 0) {
            totalSecs = 0;
            this.time_text = "00:00:00";
            document.getElementById('timer').innerHTML = 'Tempo restante: 00:00:00';

            this.props.get_processo();
        }
        else {
            this.time_text = currentHours + ":" + currentMinutes + ":" + currentSeconds;
            document.getElementById('timer').innerHTML = 'Tempo restante: ' + currentHours + ":" + currentMinutes + ":" + currentSeconds;

            this.timer_timeout =  setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    componentDidMount() {
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        if(this.props.processo.type_fiscalization=='all' || this.props.processo.type_fiscalization=='image'){
            this.videoRef.current.srcObject = this.props.stream;
        }

        this.incTimer();
        this.logs_timeout = setTimeout(() => {
            this.salvar_log();
        }, 30000);
    }

    aceitar_regras(foto) {
        this.setState({ loading_aceite: true, show_warning: false });
        let form = new FormData();
        if((this.props.processo.type_fiscalization=='all' || this.props.processo.type_fiscalization=='image') && foto!=null){
            form.append('photo', foto);
        }
        form.append('processo_id', this.props.processo.id);

        fetch(`${URL}api/aceitar_termos`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify({
                photo: foto,
                processo_id: this.props.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_aceite: true, show_error: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    this.props.aceitar_regras(resp);
                }




            } catch (err) {
                this.setState({ loading_aceite: true, show_error: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ loading_aceite: true, show_error: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        clearTimeout(this.timer_timeout);
        this.unmont_=true;
    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=aceite&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    if(resp.processo!=null && resp.processo.full_screen!=this.props.processo.full_screen){
                        this.props.mudarProcesso(resp.processo);
                    }
                    if (this.unmont_ == false) {
                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }




    render() {

        return (
            <div >
                {<div hidden={this.props.processo.type_fiscalization == 'none'} id='timer'
                    style={{ position: 'fixed', bottom: 0, right: 0, backgroundColor: 'white', fontSize: '1rem', color: 'black', zIndex: 1000, padding: '5px 5px 5px 5px' }}>
                    
                </div>}
                <hr />
                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem', fontSize: '20px', textAlign: 'center' }}>
                    <b>Regras:</b>
                </p>
                <div style={{ fontSize: '1rem', color: 'black', marginBottom: '0.4rem', whiteSpace: 'pre-line', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: this.props.processo.regras }}>
                </div>
                <hr />




                {this.state.loading_aceite == false && <div className="row">
                    <div className="col-12">
                        <button type="button" id="accept" onClick={this.onAceite.bind(this)} className="btn btn-success btn-lg waves-effect waves-light"
                            style={{ width: '100%' }}>Aceitar regras da avaliação</button>
                    </div>
                </div>}
                {this.state.loading_aceite == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
                <canvas ref={this.canvas} id="background-canvas" style={{ position: 'absolute', top: '-99999999px', left: '-9999999999px' }}></canvas>
                <video ref={this.videoRef} muted autoPlay id="background-canvas" style={{ height: '100px', position: 'absolute', top: 200, zIndex: -1 }}></video>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={this.onConfirm.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    show={this.state.show_warning}
                    confirmBtnText='Aceito'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Aceitando as regras será tirada uma foto sua

                    </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    Algo deu errado. Por favor, tente novamente
                </SweetAlert>
            </div>

        );
    }

    onConfirm() {
        this.setState({ show_warning: false });
        this.canvas.current.height = this.videoRef.current.videoHeight;
        this.canvas.current.width = this.videoRef.current.videoWidth;
        this.canvas.current.getContext('2d').drawImage(this.videoRef.current, 0, 0);
        this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg', 0.15));
    }

    onCancel() {
        this.setState({ show_warning: false });
    }

    onAceite() {
        if(this.props.processo.type_fiscalization=='all' || this.props.processo.type_fiscalization=='image'){
            this.setState({ show_warning: true });
        }
        else {
            this.aceitar_regras(null);
        }
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        faculdade: state.AppReducer.faculdade,
        stream: state.AppReducer.stream,
        token: state.AppReducer.token,
    }
);

export default withRouter(connect(mapsStateToProps, { logout,mudarProcesso })(AceiteRegras));


